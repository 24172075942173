import React, { useEffect, useState } from "react";
import './App.css';
import ShowSudoku from './components/ShowSudoku';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
const useStyles = makeStyles((theme) => ({
  root: {
    width: 300,
  },
  margin: {
    height: theme.spacing(3),
  },
}));

// let emptyBoard = [
//   [0, 0, 0, 0, 0, 0, 0, 0, 0],
//   [0, 0, 0, 0, 0, 0, 0, 0, 0],
//   [0, 0, 0, 0, 0, 0, 0, 0, 0],
//   [0, 0, 0, 0, 0, 0, 0, 0, 0],
//   [0, 0, 0, 0, 0, 0, 0, 0, 0],
//   [0, 0, 0, 0, 0, 0, 0, 0, 0],
//   [0, 0, 0, 0, 0, 0, 0, 0, 0],
//   [0, 0, 0, 0, 0, 0, 0, 0, 0],
//   [0, 0, 0, 0, 0, 0, 0, 0, 0]
// ];
let board = [
  [0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0]
];

//globalne hracie pole 9x9=81 objektov
window.sudoku=[
  {"val":8,"perm":true,"hint":0},{"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},  {"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},  {"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},
  {"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},  {"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},  {"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},
  {"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},  {"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},  {"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},

  {"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},  {"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},  {"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},
  {"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},  {"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},  {"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},
  {"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},  {"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},  {"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},

  {"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},  {"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},  {"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},
  {"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},  {"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},  {"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},
  {"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},  {"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},  {"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0},{"val":0,"perm":false,"hint":0}
];

function App() {
  const classes = useStyles();
  const [obtiaznost, setObtiaznost] = React.useState(35);
  const [appState,setAppState]=useState(0); //stav aplikacie, 0=intro, 1=game, 2=gameover
  const [sudokuState,setSudokuState]=useState([]);  //hracie pole
  // useEffect(() => {
  //   setSudokuState(...sudokuState,window.sudoku);
  // }, []);

//*************************** GENERATOR ******************************************/
function isValid(board, row, col, k) {
  for (let i = 0; i < 9; i++) {
      const m = 3 * Math.floor(row / 3) + Math.floor(i / 3);
      const n = 3 * Math.floor(col / 3) + i % 3;
      if (board[row][i] === k || board[i][col] === k || board[m][n] === k) {
        return false;
      }
  }
  return true;
}
function makeSudoku(data) {
for (let i = 0; i < 9; i++) {
  for (let j = 0; j < 9; j++) {
    if (data[i][j] === 0) {
      let tmp=parseInt(Math.random()*9);
      for (let k = 1; k <= 9; k++) {
        tmp++;if (tmp>9) tmp=1;
        if (isValid(data, i, j, tmp)) {
          //data[i][j] = `${tmp}`;
          data[i][j] = tmp;
        if (makeSudoku(data)) {
         return true;
        } else {
         data[i][j] = 0;
        }
       }
     }
     return false;
   }
 }
}
//console.log(data);
return true;
}
//*********************************************************************/

//nastartovanie hry
  const initGame=()=>{
    if (window.isMobile) alert('som na mobile');
    let i=0;let x=0;let y=0;
    //vynulujem board
    for (i=0;i<81;i++) {board[parseInt(i%9)][parseInt(i/9)]=0;};
    //vygenerujem do sudoku nahodne cisla
    makeSudoku(board);
    //podla obtiaznosti zo sudoku odstranim niektore cisla a nahradim ich 0
    console.log(obtiaznost);
    for (i=0;i<obtiaznost;i++) {
      x=parseInt((Math.random()*8))+1;
      y=parseInt((Math.random()*8))+1;
      board[x][y]=0;
    }    
    //naplnim globalny array so sudoku
    for (i=0;i<81;i++) {
      x=parseInt(i%9);y=parseInt(i/9);
      window.sudoku[i].val=board[x][y];
      window.sudoku[i].hint=0;
      //nastavym ktore cisla v tajnicke su permanentne (vsetky okrem 0)
      (board[x][y]>0)?window.sudoku[i].perm=true:window.sudoku[i].perm=false;
    }
    //nastavim state pre komponenty
    setSudokuState(window.sudoku);
    //prepnem sa do hry
    setAppState(1);
  }

  //vzdatie sa
  const giveUpGame=()=>{
    setAppState(0);
  }

  //slider - marks na slideri
  const marks = [
    {
      value: 20,
      label: 'Ľahké',
    },
    {
      value: 35,
      label: 'Mierne',
    },
    {
      value: 50,
      label: 'Stredné',
    },
    {
      value: 65,
      label: 'Ťažké',
    },
  ];

  // function valuetext(value) {
  //   return `zzzz${value}°C`;
  // }

  //slider-zabezpecuje preskoky len po povolenych hodnotach
  function valueLabelFormat(value) {
    return marks.findIndex((mark) => mark.value === value) + 1;
  }

  //slider-event handler pre zmenu obtiaznosti
  const zmenObtiaznost = (event, newValue) => {
    setObtiaznost(newValue);
  };

  //main
  const appMain=()=>{
    if (appState===0) {  //intro
      return (
        <div>
          <h1>Sudoku</h1>
          <div className={classes.root}>
            {/* <Typography id="discrete-slider-always" gutterBottom> */}
            <table style={{"width":"500px"}}>
              <tr><td>
              Obtiažnosť
              </td>
            {/* </Typography><br /><br /> */}
            <td style={{"width":"300px"}}>
            <Slider
              value={obtiaznost}
              min={20}
              max={65}
              defaultValue={35}
              onChange={zmenObtiaznost}
              valueLabelFormat={valueLabelFormat}
              aria-labelledby="discrete-slider-restrict"
              step={null}
              valueLabelDisplay="auto"
              marks={marks}
              valueLabelDisplay="on"
            />
            </td>
            </tr>
            </table>
          </div>

          <button onClick={()=>{initGame();}}>Spust hru</button>
        </div>
      )
    } else if (appState===1) {  //gameplay
      return (
        <div>
          <h1>Sudoku</h1>
          <ShowSudoku sudoku={sudokuState}/><br />
          <button onClick={()=>{giveUpGame();}}>Vzdať sa</button>
        </div>
      )
    }
  }

  return (
    <div className="App">
      {appMain()}
    </div>
  );
}

export default App;
