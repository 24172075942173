import React, { useEffect, useState } from "react";
import "./style.css";

const ShowSudoku=(props)=>{
  let output=[];
  const emptyBoard = [
    [0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0]
];
  //const [grid,setGrid]=useState([]);
  // useEffect(() => {
  //   console.log(props.sudoku);
  //   if (props.sudoku.length===81) {
  //     genGrid();
  //   }
  // }, [props.sudoku]);

//*************************** GENERATOR ******************************************/
function isValid(board, row, col, k) {
  for (let i = 0; i < 9; i++) {
      const m = 3 * Math.floor(row / 3) + Math.floor(i / 3);
      const n = 3 * Math.floor(col / 3) + i % 3;
      if (board[row][i] === k || board[i][col] === k || board[m][n] === k) {
        return false;
      }
  }
  return true;
}

function makeSudoku(data) {
for (let i = 0; i < 9; i++) {
  for (let j = 0; j < 9; j++) {
    if (data[i][j] === 0) {
      let tmp=parseInt(Math.random()*9);
      for (let k = 1; k <= 9; k++) {
        tmp++;if (tmp>9) tmp=1;
        if (isValid(data, i, j, tmp)) {
          //data[i][j] = `${tmp}`;
          data[i][j] = tmp;
        if (makeSudoku(data)) {
         return true;
        } else {
         data[i][j] = 0;
        }
       }
     }
     return false;
   }
 }
}
console.log(data);
return true;
}
//*********************************************************************/


function skuska(e,sss) {
  //alert(sss);

}




const drawGrid=()=>{
  if (props.sudoku.length!==81) return;
  console.log(props.sudoku);
  output=[];let line='';let pos=0;
  for (let i=0;i<9;i++) {
    //line='<tr className="line-el">';
    let riadok=[];
    for (let j=0;j<9;j++) {
      let pos=i*9+j;  //calc possition in array
      if (props.sudoku[pos].perm===true) {
        //len zobrazenie permanentneho cisla
        riadok.push(<td key={pos}><div id={"c"+j+i} className="permGridCell"><div className="permGridCellInner">{props.sudoku[pos].val}</div></div></td>);
      } else {
        riadok.push(<td key={pos}><div id={"c"+j+i} className="modGridCell"><input type="text" className="inputHintTag" maxlength="8"/><br /><input type="text" onClick={(e)=>{skuska(e,pos)}} className="inputTag" maxlength="1" /></div></td>);
      }
      //riadok.push(<td key={pos}><div onClick={(e)=>selectLine(e,'r'+i)} onDoubleClick={(e)=>{callFunction(e,props.rows[i][cols],props.rows[i][cols+1])}} className="rowsdatagridtd">{props.rows[i][j]}</div></td>);
    }
    //line+='</tr>';
    output.push(<tr id={'r'+i} key={'key'+pos}>{riadok}</tr>);
  }
  //setGrid(output);

  return(<div>
    <table className="sudokuTable">
        <tbody>
          {output}
        </tbody>
    </table>
    <button onClick={(e)=>{makeSudoku(emptyBoard);}}>Spusti</button>
    </div>);


}

  return(
    <>
      {drawGrid()}
    </>
  )
}

export default ShowSudoku;